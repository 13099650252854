import React, { Fragment, useState, useEffect } from 'react';

import Donors from './Donors';
import Organizations from './Organizations';
import Contributions from './Contributions';
import Updates from './Updates';

/* UI COMPONENTS */
import {
    AppBar,
    Toolbar,
    withStyles,
    Grid,
    Typography,
    TextField,
    Button,
    CircularProgress,
    Paper,
    SnackbarContent,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogContent,
    DialogActions
} from '@material-ui/core';
import moment from 'moment';
import NumberFormat from 'react-number-format';

import PeopleIcon from '@material-ui/icons/SupervisedUserCircle';
import HeartIcon from '@material-ui/icons/Favorite';
import MoneyIcon from '@material-ui/icons/MonetizationOn';
import CashIcon from '@material-ui/icons/LocalAtm';
import BackIcon from '@material-ui/icons/KeyboardBackspace';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import UpdateIcon from '@material-ui/icons/RssFeed';


/* ASSETS */
import wordmark from '../../assets/images/wordmark.png';
import GlobalStyle from '../../style/Global';


function Dashboard({ classes, firestore, AUTH, DB, CDN, setScene }) {
    window.scrollTo(0, 0);

    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState(null);
    const [metrics, setMetrics] = useState(null);
    const [notificationMessage, setNotificationMessage] = useState(null);
    const [displayNotificationModal, setDisplayNotificationModal] = useState(false);
    const [confirmNotificationVisible, setConfirmNotificationVisible] = useState(false);
    const [dashboardScene, setDashboardScene] = useState('index');

    const showFeedback = (message, type) => {
        setMessage({
            style: type,
            content: message
        });

        setTimeout(() => {setMessage(null)}, 7000);
    }

    const [donors, setDonors] = useState([1,2,3,4,5,6,7]);

    const sendNotification = () => {
        setConfirmNotificationVisible(true);
    }

    const createNotification = () => {
        setLoading(true);

        DB.collection('notifications').add({
            message: notificationMessage,
            createdAt: firestore.FieldValue.serverTimestamp()
        })
        .then(() => {
            setConfirmNotificationVisible(false);
            setDisplayNotificationModal(false);
            setLoading(false);
            showFeedback('Notifications Sent!', classes.success);
            setNotificationMessage(null);
        })
        .catch((error) => {
            setLoading(false);
            showFeedback(error.message, classes.error);
        })
    };

    const logOut = () => {
        AUTH.signOut();
        showFeedback('Logged out', classes.error);
        setScene('log in');
    };

    useEffect(() => {
        AUTH.onAuthStateChanged((currentUser) => {
            if (currentUser) {
                const id = currentUser.uid;
    
                DB.collection('metrics').doc('overall').get()
                .then((metricsDoc) => {
                    const metrics = metricsDoc.data();
                    
                    setMetrics(metrics);
                    setLoading(false);
                })
                .catch((error) => {
                    showFeedback(error.message, classes.error)
                    setLoading(false);
                })
            } else {
                showFeedback('Please log in', classes.error)
                return setScene('log in');
            }
        })
    }, []);


    return(
        <div>
            <AppBar position="static" style={{backgroundColor: '#3f8b24', boxShadow: 'none'}}>
                <Toolbar>
                    <img src={wordmark} style={{height: '2rem'}}/>
                    <div style={{flexGrow: 1}}/>
                    <Button onClick={() => setDisplayNotificationModal(true)} style={{color: '#f5f5f5', textTransform: 'none', marginRight: '1rem'}}>send notification</Button>
                    <Button onClick={logOut} style={{color: '#f5f5f5', textTransform: 'none', marginRight: '1rem'}}>log out</Button>
                </Toolbar>
            </AppBar>
            {dashboardScene === 'index' && <Grid container style={{backgroundColor: '#f5f5f5'}}>
                <Grid item container xs={12} style={{minHeight: '15rem', backgroundColor: '#444', textAlign: 'center'}}>
                    {message && <SnackbarContent
                        style={{zIndex: 99999999999}}
                        className={message.style}
                        message={message.content}
                    />}
                    <Grid item xs={12} style={{margin: '1rem'}}>
                        <Typography variant="h6" component="h1" style={{fontWeight: '300', color: '#f5f5f5'}}>{!loading ? 'Admin' : <CircularProgress style={{color: '#f5f5f5'}}/>}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} style={{padding: '1rem'}}>
                        <Typography variant="h6" component="h1" style={{fontFamily: 'helvetica', fontWeight: '200', fontSize: '3rem', lineHeight: 1, color: '#f5f5f5'}}>{!loading ? metrics && metrics.donors : <CircularProgress style={{color: '#f5f5f5'}}/>}</Typography>
                        <Typography variant="h6" component="h1" style={{fontWeight: '300', color: '#f5f5f5'}}>donors</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} style={{padding: '1rem'}}>
                        <Typography variant="h6" component="h1" style={{fontFamily: 'helvetica', fontWeight: '200', fontSize: '3rem', lineHeight: 1, color: '#f5f5f5'}}>{!loading ? metrics && metrics.organizations : <CircularProgress style={{color: '#f5f5f5'}}/>}</Typography>
                        <Typography variant="h6" component="h1" style={{fontWeight: '300', color: '#f5f5f5'}}>organizations</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} style={{padding: '1rem'}}>
                        <Typography variant="h6" component="h1" style={{fontFamily: 'helvetica', fontWeight: '200', fontSize: '3rem', lineHeight: 1, color: '#f5f5f5'}}>{!loading ? metrics && metrics.contributions : <CircularProgress style={{color: '#f5f5f5'}}/>}</Typography>
                        <Typography variant="h6" component="h1" style={{fontWeight: '300', color: '#f5f5f5'}}>contributions</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} style={{padding: '1rem'}}>
                        <Typography variant="h6" component="h1" style={{fontFamily: 'helvetica', fontWeight: '200', fontSize: '3rem', lineHeight: 1, color: '#f5f5f5'}}>${!loading ? metrics && parseFloat(metrics.fundsRaised/100).toFixed(2) : <CircularProgress style={{color: '#f5f5f5'}}/>}</Typography>
                        <Typography variant="h6" component="h1" style={{fontWeight: '300', color: '#f5f5f5'}}>funds raised</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} style={{padding: '2rem'}}>
                    <Paper elevation={3} onClick={() => setDashboardScene('donors')} style={{textAlign: 'center', padding: '4rem', cursor: 'pointer'}}>
                        <PeopleIcon style={{color: '#3f8b24', fontSize: '7rem'}}/>
                        <Typography variant="h6" component="h1" style={{fontFamily: 'helvetica', fontWeight: '200', fontSize: '2rem', lineHeight: 1}}>donors</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} style={{padding: '2rem'}}>
                    <Paper elevation={3} onClick={() => setDashboardScene('organizations')} style={{textAlign: 'center', padding: '4rem', cursor: 'pointer'}}>
                        <HeartIcon style={{color: '#3f8b24', fontSize: '7rem'}}/>
                        <Typography variant="h6" component="h1" style={{fontFamily: 'helvetica', fontWeight: '200', fontSize: '2rem', lineHeight: 1}}>organizations</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} style={{padding: '2rem', cursor: 'pointer'}}>
                    <Paper elevation={3} onClick={() => setDashboardScene('contributions')} style={{textAlign: 'center', padding: '4rem', cursor: 'pointer'}}>
                        <MoneyIcon style={{color: '#3f8b24', fontSize: '7rem'}}/>
                        <Typography variant="h6" component="h1" style={{fontFamily: 'helvetica', fontWeight: '200', fontSize: '2rem', lineHeight: 1}}>contributions</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} style={{padding: '2rem'}}>
                    <Paper elevation={3} onClick={() => setDashboardScene('updates')} style={{textAlign: 'center', padding: '4rem', cursor: 'pointer'}}>
                        <UpdateIcon style={{color: '#3f8b24', fontSize: '7rem'}}/>
                        <Typography variant="h6" component="h1" style={{fontFamily: 'helvetica', fontWeight: '200', fontSize: '2rem', lineHeight: 1}}>updates</Typography>
                    </Paper>
                </Grid>
            </Grid>}
            {dashboardScene === 'donors' && <Donors DB={DB} setDashboardScene={setDashboardScene} />}
            {dashboardScene === 'organizations' && <Organizations DB={DB} setDashboardScene={setDashboardScene} />}
            {dashboardScene === 'contributions' && <Contributions DB={DB} setDashboardScene={setDashboardScene} />}
            {dashboardScene === 'updates' && <Updates firestore={firestore} DB={DB} CDN={CDN} organizationId={AUTH.currentUser.uid} setMessage={setMessage} setDashboardScene={setDashboardScene} />}

            <Dialog open={displayNotificationModal} onClose={() => setDisplayNotificationModal(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{loading ? <CircularProgress size={25} style={{color: 'forestgreen'}}/> : 'Push Notification'}</DialogTitle>
                {confirmNotificationVisible && <div style={{padding: '0 1.5rem'}}>
                    <h2 style={{fontSize: '1rem'}}>Are you sure you want to send the following message to donors?</h2>
                    <p>{notificationMessage}</p>
                </div>}
                {!confirmNotificationVisible && <DialogContent style={{minWidth: '20vw'}}>
                    <TextField
                        fullWidth
                        multiline
                        rows={2}
                        disabled={loading}
                        className={`${classes.input} ${classes.blockInput}`}
                        variant="outlined"
                        placeholder="push notification message..."
                        value={notificationMessage}
                        onChange={(event) => setNotificationMessage(event.target.value)}
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline
                            }
                        }}
                    />
                </DialogContent>}
                <DialogActions>
                    <Button disabled={loading} onClick={() => {setDisplayNotificationModal(false); setConfirmNotificationVisible(false); setNotificationMessage(null)}} variant="contained" style={{backgroundColor: 'transparent', color: '#222', textTransform: 'none', marginBottom: '1rem', borderRadius: '25px'}}>
                        Cancel
                    </Button>
                    {!confirmNotificationVisible && <Button disabled={loading} onClick={sendNotification} variant="contained" style={{backgroundColor: '#3f8b24', color: '#fff', textTransform: 'none', marginBottom: '1rem', borderRadius: '25px'}}>
                        Send
                    </Button>}
                    {confirmNotificationVisible && <Button disabled={loading} onClick={createNotification} variant="contained" style={{backgroundColor: '#3f8b24', color: '#fff', textTransform: 'none', marginBottom: '1rem', borderRadius: '25px'}}>
                        Yes
                    </Button>}                    
                </DialogActions>
            </Dialog>
        </div>
    );

}

export default withStyles(GlobalStyle)(Dashboard);