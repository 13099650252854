import { useState, useEffect } from 'react';
import GlobalStyle from '../../style/Global';
import {
    withStyles,
    Grid,
    Typography,
    TextField,
    Button,
    IconButton,
    Snackbar,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogContent,
    DialogActions
} from '@material-ui/core';
import moment from 'moment';

import {
    DataGrid
} from '@material-ui/data-grid';

import BackIcon from '@material-ui/icons/KeyboardBackspace';
import SearchIcon from '@material-ui/icons/Search';
import DownloadIcon from '@material-ui/icons/GetApp';

import { CSVDownload } from "react-csv";

function Contributions ({
    DB,
    classes,
    setDashboardScene
}) {
    const [loading, setLoading] = useState(true);
    const [keyword, setKeyword] = useState("");
    const [contributions, setContributions] = useState([]);
    const [organizationNames, setOrganizationNames] = useState([]);
    const [filteredContributions, setFilteredContributions] = useState([]);
    const [contributionsForExport, setContributionsForExport] = useState([]);
    const [csvLinkVisible, setCSVLinkVisible] = useState(false);
    const [showSearchForm, setShowSearchForm] = useState(false);


    const columns = [
        {field: 'donorName', headerName: 'Donor Name', width: 200},
        {field: 'donorEmail', headerName: 'Email', width: 325},
        {field: 'amount', headerName: 'Contribution', width: 150},
        {field: 'date', headerName: 'Date', width: 150},
        {field: 'organization', headerName: 'Organization', width: 300},
        {field: 'institutionName', headerName: 'Bank', width: 300},
        {field: 'accountMask', headerName: 'Account Number (Last 4)', width: 150},
    ]

    const getOrganizationNames = () => {
        const organizationNamesData = {};
        DB.collection('organizations').get()
        .then((organizationsSnapshot) => {

            organizationsSnapshot.forEach((orgDoc) => {
                const org = orgDoc.data();
                org.id = orgDoc.id;

                organizationNamesData[orgDoc.id] = org.organizationName;
            })

            setOrganizationNames(organizationNamesData);
        })
        .then(() => {
            getContributions(organizationNamesData);
        })
    }

    const getContributions = (orgNames) => {
        DB.collection('contributions').get()
        .then((contributionsSnapshot) => {
            const contributionsData = [];
            const contributionsForExportData = [['DONOR NAME', 'AMOUNT', 'DATE', 'ORGANIZATION(S)', 'SOURCE']];
    
            contributionsSnapshot.forEach((contributionDoc) => {
                let contribution = contributionDoc.data();
                contribution.id = contributionDoc.id;
                contribution.amount = `$${parseFloat(contribution.amount/100).toFixed(2)}`;
                contribution.date = `${moment().month(contribution.month).format('MMM')} ${moment().date(contribution.date).format('Do')}, ${contribution.year}`;
                contribution.organization = orgNames[contribution.organizationId]; console.log(contribution.organization);
                contribution.source = `${contribution.accountId[0]}${contribution.accountId[1]}${contribution.accountId[2]}${contribution.accountId[3]}****`;
                contributionsData.push(contribution);
                
                contributionsForExportData.push([
                    contribution.donorName,
                    contribution.donorEmail,
                    contribution.amount,
                    contribution.date,
                    contribution.organization,
                    contribution.institutionName,
                    contribution.accountMask,
                ])
            })
    
            setContributions(contributionsData);
            setFilteredContributions(contributionsData);
            setContributionsForExport(contributionsForExportData);
            setLoading(false);
        })
    }

    useEffect(() => {
        getOrganizationNames();
    }, []);


    const filterContributions = (reset = false) => {
        setLoading(true);

        const currentFilteredContributions = [];

        if (reset) {
            setLoading(false);
            setKeyword("");
            setShowSearchForm(false);
            return setFilteredContributions(contributions);
        }

        filteredContributions.forEach((contribution) => {
            if (contribution.donorName.toLowerCase().indexOf(keyword.toLowerCase()) > -1) {
                currentFilteredContributions.push(contribution);
            }

            if (contribution.donorId.toLowerCase().indexOf(keyword.toLowerCase()) > -1) {
                currentFilteredContributions.push(contribution);
            }

            if (contribution.organization.toLowerCase().indexOf(keyword.toLowerCase()) > -1) {
                currentFilteredContributions.push(contribution);
            }
        })

        setFilteredContributions(currentFilteredContributions);
        setKeyword("");
        setShowSearchForm(false);
        setLoading(false);
    }


    return (<Grid item container xs={12}>
        <Grid item container xs={12} style={{minHeight: '3rem', backgroundColor: '#444', textAlign: 'center'}}>
            <Grid item xs={12} style={{margin: '1rem', textAlign: 'left', position: 'relative'}}>
                <Typography variant="h6" component="h1" style={{fontWeight: '300', fontSize: '2rem', color: '#f5f5f5'}}>Contributions</Typography>
                <Typography onClick={() => setDashboardScene('index')} variant="h6" component="h1" style={{fontFamily: 'helvetica', fontSize: '1rem', cursor: 'pointer', fontWeight: '200', color: '#f5f5f5'}}><BackIcon style={{verticalAlign: 'bottom'}}/> go back</Typography>

                <IconButton onClick={() => {
                        setCSVLinkVisible(true);
                        setTimeout(() => {setCSVLinkVisible(false)}, 1000);
                    }} aria-label="add" style={{position: 'absolute', top: '30%', right: '4rem'}} size="large"
                >
                    <DownloadIcon style={{color: '#f5f5f5'}}/>
                </IconButton>
                {csvLinkVisible && <CSVDownload data={contributionsForExport} target="_blank" />}
                <IconButton onClick={() => setShowSearchForm(true)} aria-label="add" style={{position: 'absolute', top: '30%', right: '1rem'}} size="large">
                    <SearchIcon style={{color: '#f5f5f5'}}/>
                </IconButton>
            </Grid>
        </Grid>
        <Grid item xs={12} style={{color: '#222'}}>
            <DataGrid autoHeight disableMultipleSelection onRowClick={(param) => {}} loading={loading} rows={filteredContributions} columns={columns} pageSize={25}/>

            <Dialog open={showSearchForm} onClose={() => setShowSearchForm(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{loading ? <CircularProgress size={25} style={{color: 'forestgreen'}}/> : 'Search Contributions'}</DialogTitle>
                <DialogContentText style={{paddingLeft: '1.5rem', paddingRight: '1.5rem'}}>
                    <p style={{fontFamily: 'helvetica neue', fontWeight: '400'}}>Find contributions by donor email, name, or organization name.</p>
                </DialogContentText>
                <DialogContent>
                    <TextField
                        fullWidth
                        disabled={loading}
                        className={`${classes.input} ${classes.blockInput}`}
                        variant="outlined"
                        placeholder="keyword..."
                        value={keyword}
                        onChange={(event) => setKeyword(event.target.value)}
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline
                            }
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={() => {setShowSearchForm(false)}} variant="contained" style={{backgroundColor: 'transparent', color: '#222', textTransform: 'none', marginBottom: '1rem', borderRadius: '25px'}}>
                        Cancel
                    </Button>
                    <Button disabled={loading} onClick={() => filterContributions(true)} variant="contained" style={{backgroundColor: 'transparent', color: '#222', textTransform: 'none', marginBottom: '1rem', borderRadius: '25px'}}>
                        Reset
                    </Button>
                    <Button disabled={loading} onClick={() => filterContributions()} variant="contained" style={{backgroundColor: '#3f8b24', color: '#fff', textTransform: 'none', marginBottom: '1rem', borderRadius: '25px'}}>
                        Search
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    </Grid>)
}

export default withStyles(GlobalStyle)(Contributions);