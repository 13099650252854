import { useState, useEffect } from 'react';
import GlobalStyle from '../../style/Global';
import {
    withStyles,
    Grid,
    Typography,
    TextField,
    SnackbarContent,
    Button,
    IconButton,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogContent,
    DialogActions
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import DownloadIcon from '@material-ui/icons/GetApp';
import BackIcon from '@material-ui/icons/KeyboardBackspace';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import {
    DataGrid
} from '@material-ui/data-grid';

import { CSVDownload } from "react-csv";

function Organizations ({
    DB,
    classes,
    setDashboardScene
}) {
    const [loading, setLoading] = useState(true);
    const [organizations, setOrganizations] = useState([]);
    const [organizationsForExport, setOrganizationsForExport] = useState([]);
    const [csvLinkVisible, setCSVLinkVisible] = useState(false);
    const [currentOrganization, setCurrentOrganization] = useState(null);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [editingServiceFee, setEditingServiceFee] = useState(false);
    const [newServiceFee, setNewServiceFee] = useState(0);
    const [message, setMessage] = useState(null);

    const columns = [
        {field: 'organizationName', headerName: 'Name', width: 200},
        {field: 'emailAddress', headerName: 'Email', width: 250},
        {field: 'donors', headerName: 'Donors', width: 200},
        {field: 'fundsRaised', headerName: 'Funds Raised', width: 200},
        {field: 'contributions', headerName: 'Contributions', width: 200},
        {field: 'serviceFee', headerName: 'Service Fee %', width: 200},
        {field: 'active', headerName: 'Active', width: 150},
        {field: 'category', headerName: 'Category', width: 200},
    ]


    const getOrganizations = () => {
        DB.collection('organizations').get()
        .then((organizationsSnapshot) => {
            const organizationsData = [];
            const organizationsForExportData = [['NAME', 'EMAIL', 'EIN', 'CATEGORY', 'DONORS', 'FUNDS RAISED', 'CONTRIBUTIONS', 'SERVICE FEE', 'PAYOUTS SETUP', 'ACTIVE', 'WEBSITE', 'ZIP CODE']];

            organizationsSnapshot.forEach((organizationDoc) => {
                let organization = organizationDoc.data();
                organization.fundsRaised = `$${organization.private.fundsRaised/100}`;
                organization.contributions = organization.private.contributions;
                organization.serviceFee = organization.private.serviceFee;
                organization.donors = organization.private.donors;
                organization.active = organization.private.active;

                organizationsData.push(organization);

                const exportFormattedOrganization = [
                    organization.organizationName,
                    organization.emailAddress,
                    organization.ein,
                    organization.category,
                    organization.private.donors,
                    organization.private.fundsRaised,
                    organization.private.contributions,
                    organization.private.serviceFee,
                    organization.private.payoutsSetup,
                    organization.private.active,
                    organization.website,
                    organization.zipCode
                ];

                organizationsForExportData.push(exportFormattedOrganization);
            })

            setOrganizations(organizationsData);
            setOrganizationsForExport(organizationsForExportData);
            setLoading(false);
        })
    }


    const toggleOrganization = () => {
        setLoading(true);

        DB.collection('organizations').doc(currentOrganization.id).update({
            'private.active': currentOrganization.active ? false : true
        })
        .then(() => {
            showFeedback(`${currentOrganization.organizationName} has been ${currentOrganization.active ? 'deactivated' : 'activated'}`, classes.success);
            setShowUpdateForm(false);
            getOrganizations();
        })
        .catch((error) => {
            showFeedback(error.message, classes.error)
            setLoading(false);
        })
    }

    const showFeedback = (message, type) => {
        setMessage({
            style: type,
            content: message
        });

        setTimeout(() => {setMessage(null)}, 5000);
    }

    const updateServiceFee = () => {
        setLoading(true);

        DB.collection('organizations').doc(currentOrganization.id).update({
            'private.serviceFee': parseInt(newServiceFee)
        })
        .then(() => {
            setShowUpdateForm(false);
            showFeedback('Service updated successfully!', classes.success)
            getOrganizations();
        })
        .catch((error) => {
            showFeedback(error.message, classes.error);
            setLoading(false);
        })

    };


    useEffect(() => {
        getOrganizations()
    }, []);

    return (<Grid item container xs={12}>
        {message && <SnackbarContent
            style={{zIndex: 99999999999}}
            className={message.style}
            message={message.content}
        />}
        <Grid item container xs={12} style={{minHeight: '3rem', backgroundColor: '#444', textAlign: 'center'}}>
            <Grid item xs={12} style={{margin: '1rem', textAlign: 'left', position: 'relative'}}>
                <Typography variant="h6" component="h1" style={{fontWeight: '300', fontSize: '2rem', color: '#f5f5f5'}}>Organizations</Typography>
                <Typography onClick={() => setDashboardScene('index')} variant="h6" component="h1" style={{fontFamily: 'helvetica', fontSize: '1rem', cursor: 'pointer', fontWeight: '200', color: '#f5f5f5'}}><BackIcon style={{verticalAlign: 'bottom'}}/> go back</Typography>
                <IconButton onClick={() => {
                        setCSVLinkVisible(true);
                        setTimeout(() => {setCSVLinkVisible(false)}, 1000);
                    }} aria-label="add" style={{position: 'absolute', top: '30%', right: 0}} size="large"
                >
                    <DownloadIcon style={{color: '#f5f5f5'}}/>
                </IconButton>
                {csvLinkVisible && <CSVDownload data={organizationsForExport} target="_blank" />}
            </Grid>
        </Grid>
        <Grid item xs={12} style={{color: '#222'}}>
            <DataGrid className={classes.ccDataGrid} autoHeight disableMultipleSelection onRowClick={(param) => {setNewServiceFee(param.data.serviceFee); setCurrentOrganization(param.data); setShowUpdateForm(true)}} loading={loading} rows={organizations} columns={columns} pageSize={25}/>

            <Dialog open={showUpdateForm} onClose={() => {setShowUpdateForm(false); setCurrentOrganization(null)}} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{loading ? <CircularProgress size={25} style={{color: 'forestgreen'}}/> : currentOrganization && currentOrganization.organizationName}</DialogTitle>
                {!editingServiceFee && <DialogContentText style={{paddingLeft: '1.5rem', paddingRight: '1.5rem'}}>
                    <p style={{fontFamily: 'helvetica neue', fontWeight: '400'}}><b>Email:</b> {currentOrganization && currentOrganization.emailAddress}</p>
                    <p style={{fontFamily: 'helvetica neue', fontWeight: '400'}}><b>Funds Raised:</b> {currentOrganization && currentOrganization.fundsRaised}</p>
                    <p style={{fontFamily: 'helvetica neue', fontWeight: '400'}}><b>Donors:</b> {currentOrganization && currentOrganization.donors}</p>
                    <p style={{fontFamily: 'helvetica neue', fontWeight: '400'}}><b>Contributions:</b> {currentOrganization && currentOrganization.contributions}</p>
                    <p style={{fontFamily: 'helvetica neue', fontWeight: '400'}}><b>Service Fee:</b> {currentOrganization && `${currentOrganization.serviceFee}%`}</p>
                </DialogContentText>}
                {!editingServiceFee && <DialogContent>
                    {currentOrganization && currentOrganization.description}
                </DialogContent>}
                {editingServiceFee && <DialogContent>
                    You're about to update the percentage fee that Champ's Change will receive from each donor contribution for this organization.
                </DialogContent>}
                {editingServiceFee && <DialogContent>
                    <TextField
                        fullWidth
                        disabled={loading}
                        className={`${classes.input} ${classes.blockInput}`}
                        variant="outlined"
                        placeholder="organization name..."
                        label="New Service Fee %"
                        value={newServiceFee}
                        onChange={(event) => setNewServiceFee(event.target.value)}
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline
                            }
                        }}
                    />
                </DialogContent>}
                <DialogActions>
                    <Button disabled={loading} onClick={() => {setShowUpdateForm(false); setCurrentOrganization(null); setEditingServiceFee(false)}} variant="contained" style={{backgroundColor: 'transparent', color: '#222', textTransform: 'none', marginBottom: '1rem', borderRadius: '25px'}}>
                        Cancel
                    </Button>
                    {!editingServiceFee && <Button disabled={loading} onClick={() => setEditingServiceFee(true)} variant="contained" style={{backgroundColor: '#444', color: '#f5f5f5', textTransform: 'none', marginBottom: '1rem', borderRadius: '25px'}}>
                        Edit Service Fee
                    </Button>}
                    {editingServiceFee && <Button disabled={loading} onClick={updateServiceFee} variant="contained" style={{backgroundColor: '#3f8b24', color: '#f5f5f5', textTransform: 'none', marginBottom: '1rem', borderRadius: '25px'}}>
                        Update Service Fee
                    </Button>}
                    {!editingServiceFee && <Button disabled={loading} onClick={toggleOrganization} variant="contained" style={{backgroundColor: '#3f8b24', color: '#f5f5f5', textTransform: 'none', marginBottom: '1rem', borderRadius: '25px'}}>
                        {currentOrganization && currentOrganization.active ? 'Deactivate' : 'Activate'}
                    </Button>}
                </DialogActions>
            </Dialog>
        </Grid>
    </Grid>)
}

export default withStyles(GlobalStyle)(Organizations);