import { useState, useEffect } from 'react';
import GlobalStyle from '../../style/Global';
import {
    withStyles,
    Grid,
    Typography,
    TextField,
    SnackbarContent,
    Button,
    IconButton,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogContent,
    DialogActions
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import DownloadIcon from '@material-ui/icons/GetApp';
import BackIcon from '@material-ui/icons/KeyboardBackspace';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import {
    DataGrid
} from '@material-ui/data-grid';

import { CSVDownload } from "react-csv";

function Donors ({
    DB,
    classes,
    setDashboardScene
}) {
    const [loading, setLoading] = useState(true);
    const [donors, setDonors] = useState([]);
    const [filteredDonors, setFilteredDonors] = useState([]);
    const [keyword, setKeyword] = useState("");
    const [donorsForExport, setDonorsForExport] = useState([]);
    const [csvLinkVisible, setCSVLinkVisible] = useState(false);
    const [currentDonor, setCurrentDonor] = useState(null);
    const [organizationNames, setOrganizationNames] = useState(null);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [showSearchForm, setShowSearchForm] = useState(false);
    const [message, setMessage] = useState(null);

    const columns = [
        {field: 'name', headerName: 'Name', width: 200},
        {field: 'emailAddress', headerName: 'Email', width: 250},
        {field: 'organizationNames', headerName: 'Organization(s)', width: 350},
        {field: 'fundsRaised', headerName: 'Funds Raised', width: 250},
        {field: 'contributions', headerName: 'Contributions', width: 250},
        {field: 'status', headerName: 'Status', width: 150},
        {field: 'active', headerName: 'Active', width: 200}
    ]

    const getOrganizationNames = () => {
        DB.collection('organizations').get()
        .then((organizationsSnapshot) => {
            const organizationNamesData = {};

            organizationsSnapshot.forEach((orgDoc) => {
                const org = orgDoc.data();
                org.id = orgDoc.id;

                organizationNamesData[orgDoc.id] = org.organizationName;
            })

            setOrganizationNames(organizationNamesData);
            getDonors(organizationNamesData);
        })
    }

    const getDonors = (orgNames = organizationNames) => {
        DB.collection('donors').get()
        .then((donorsSnapshot) => {
            const donorsData = [];
            const donorsForExportData = [['NAME', 'EMAIL', 'FUNDS RAISED', 'CONTRIBUTIONS', 'ORGANIZATIONS', 'STATUS', 'ACTIVE']];

            donorsSnapshot.forEach((donorDoc) => {
                let donor = donorDoc.data();
                donor.name = `${donor.firstName} ${donor.lastName}`;
                donor.fundsRaised = `$${parseFloat(donor.private.fundsRaised/100).toFixed(2)}`;
                donor.contributions = donor.private.contributions;
                donor.status = donor.status;
                donor.active = donor.active;

                const organizationNamesData = [];

                if (donor.organizations.length > 0) {
                    donor.organizations.forEach((org) => {
                        organizationNamesData.push(orgNames[org]);
                    })
                }

                donor.organizationNames = organizationNamesData.join('');
                donorsData.push(donor);

                const exportFormattedDonor = [
                    donor.name,
                    donor.emailAddress,
                    donor.fundsRaised,
                    donor.contributions,
                    donor.organizationNames,
                    donor.status,
                    donor.active
                ];

                donorsForExportData.push(exportFormattedDonor);
            })

            setDonors(donorsData);
            setFilteredDonors(donorsData);
            setDonorsForExport(donorsForExportData);
            setLoading(false);
        })
    }

    const filterDonors = (reset = false) => {
        setLoading(true);

        const currentFilteredDonors = [];

        if (reset) {
            setLoading(false);
            setKeyword("");
            setShowSearchForm(false);
            return setFilteredDonors(donors);
        }

        filteredDonors.forEach((donor) => {
            if (donor.id === keyword) {
                currentFilteredDonors.push(donor);
            }

            if (donor.emailAddress.toLowerCase().indexOf(keyword.toLowerCase()) > -1) {
                currentFilteredDonors.push(donor);
            }

            if (donor.name.toLowerCase().indexOf(keyword.toLowerCase()) > -1) {
                currentFilteredDonors.push(donor);
            }

            if (donor.organizationNames.toLowerCase().indexOf(keyword.toLowerCase()) > -1) {
                currentFilteredDonors.push(donor);
            }
        })

        setFilteredDonors(currentFilteredDonors);
        setKeyword("");
        setShowSearchForm(false);
        setLoading(false);
    }

    const toggleDonor = () => {
        setLoading(true);

        DB.collection('donors').doc(currentDonor.id).update({
            'private.active': currentDonor.active ? false : true
        })
        .then(() => {
            showFeedback(`${currentDonor.name} has been ${currentDonor.active ? 'deactivated' : 'activated'}`, classes.success);
            setShowUpdateForm(false);
            getDonors();
        })
        .catch((error) => {
            showFeedback(error.message, classes.error)
            setLoading(false);
        })
    }

    const showFeedback = (message, type) => {
        setMessage({
            style: type,
            content: message
        });

        setTimeout(() => {setMessage(null)}, 5000);
    }


    useEffect(() => {
        getOrganizationNames()
    }, []);

    return (<Grid item container xs={12}>
        {message && <SnackbarContent
            style={{zIndex: 99999999999}}
            className={message.style}
            message={message.content}
        />}
        <Grid item container xs={12} style={{minHeight: '3rem', backgroundColor: '#444', textAlign: 'center'}}>
            <Grid item xs={12} style={{margin: '1rem', textAlign: 'left', position: 'relative'}}>
                <Typography variant="h6" component="h1" style={{fontWeight: '300', fontSize: '2rem', color: '#f5f5f5'}}>Donors</Typography>
                <Typography onClick={() => setDashboardScene('index')} variant="h6" component="h1" style={{fontFamily: 'helvetica', fontSize: '1rem', cursor: 'pointer', fontWeight: '200', color: '#f5f5f5'}}><BackIcon style={{verticalAlign: 'bottom'}}/> go back</Typography>
                <IconButton onClick={() => {
                        setCSVLinkVisible(true);
                        setTimeout(() => {setCSVLinkVisible(false)}, 1000);
                    }} aria-label="add" style={{position: 'absolute', top: '30%', right: '4rem'}} size="large"
                >
                    <DownloadIcon style={{color: '#f5f5f5'}}/>
                </IconButton>
                {csvLinkVisible && <CSVDownload data={donorsForExport} target="_blank" />}
                <IconButton onClick={() => setShowSearchForm(true)} aria-label="add" style={{position: 'absolute', top: '30%', right: '1rem'}} size="large">
                    <SearchIcon style={{color: '#f5f5f5'}}/>
                </IconButton>
            </Grid>
        </Grid>
        <Grid item xs={12} style={{color: '#222'}}>
            <DataGrid className={classes.ccDataGrid} autoHeight disableMultipleSelection onRowClick={(param) => {setCurrentDonor(param.data); setShowUpdateForm(true)}} loading={loading} rows={filteredDonors} columns={columns} pageSize={25}/>

            <Dialog open={showUpdateForm} onClose={() => {setShowUpdateForm(false); setCurrentDonor(null)}} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{loading ? <CircularProgress size={25} style={{color: 'forestgreen'}}/> : currentDonor && currentDonor.name}</DialogTitle>
                <DialogContentText style={{paddingLeft: '1.5rem', paddingRight: '1.5rem'}}>
                    <p style={{fontFamily: 'helvetica neue', fontWeight: '400'}}><b>Email:</b> {currentDonor && currentDonor.emailAddress}</p>
                    <p style={{fontFamily: 'helvetica neue', fontWeight: '400'}}><b>Funds Raised:</b> {currentDonor && currentDonor.fundsRaised}</p>
                    <p style={{fontFamily: 'helvetica neue', fontWeight: '400'}}><b>Contributions:</b> {currentDonor && currentDonor.contributions}</p>
                </DialogContentText>
                <DialogContent>

                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={() => {setShowUpdateForm(false); setCurrentDonor(null)}} variant="contained" style={{backgroundColor: 'transparent', color: '#222', textTransform: 'none', marginBottom: '1rem', borderRadius: '25px'}}>
                        Cancel
                    </Button>
                    <Button disabled={loading} onClick={toggleDonor} variant="contained" style={{backgroundColor: '#3f8b24', color: '#fff', textTransform: 'none', marginBottom: '1rem', borderRadius: '25px'}}>
                        {currentDonor && currentDonor.active ? 'Deactivate' : 'Re-Activate'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={showSearchForm} onClose={() => setShowSearchForm(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{loading ? <CircularProgress size={25} style={{color: 'forestgreen'}}/> : 'Search Donors'}</DialogTitle>
                <DialogContentText style={{paddingLeft: '1.5rem', paddingRight: '1.5rem'}}>
                    <p style={{fontFamily: 'helvetica neue', fontWeight: '400'}}>Find donors by email address, name, id, or organization name.</p>
                </DialogContentText>
                <DialogContent>
                    <TextField
                        fullWidth
                        disabled={loading}
                        className={`${classes.input} ${classes.blockInput}`}
                        variant="outlined"
                        placeholder="keyword..."
                        value={keyword}
                        onChange={(event) => setKeyword(event.target.value)}
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline
                            }
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={() => {setShowSearchForm(false)}} variant="contained" style={{backgroundColor: 'transparent', color: '#222', textTransform: 'none', marginBottom: '1rem', borderRadius: '25px'}}>
                        Cancel
                    </Button>
                    <Button disabled={loading} onClick={() => filterDonors(true)} variant="contained" style={{backgroundColor: 'transparent', color: '#222', textTransform: 'none', marginBottom: '1rem', borderRadius: '25px'}}>
                        Reset
                    </Button>
                    <Button disabled={loading} onClick={() => filterDonors()} variant="contained" style={{backgroundColor: '#3f8b24', color: '#fff', textTransform: 'none', marginBottom: '1rem', borderRadius: '25px'}}>
                        Search
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    </Grid>)
}

export default withStyles(GlobalStyle)(Donors);