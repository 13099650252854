import { useState, useEffect } from 'react';
import GlobalStyle from '../../style/Global';
import moment from 'moment';
import {
    withStyles,
    Grid,
    Typography,
    TextField,
    Button,
    SnackbarContent,
    CircularProgress,
    Paper,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';

import {
    DataGrid
} from '@material-ui/data-grid';

import BackIcon from '@material-ui/icons/KeyboardBackspace';
import AddIcon from '@material-ui/icons/AddCircle';


function Updates ({
    DB,
    CDN,
    firestore,
    classes,
    setDashboardScene
}) {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [updates, setUpdates] = useState([]);
    const [updatedDetails, setUpdatedDetails] = useState("");
    const [currentUpdate, setCurrentUpdate] = useState(null);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [image, setImage] = useState(null);
    const [title, setTitle] = useState(null);
    const [details, setDetails] = useState(null);
    const [source, setSource] = useState(null);
 
    const columns = [
        {field: 'postedAt', headerName: 'Date', width: 200, valueGetter: (params) =>
        `${moment(params.getValue('postedAt').toDate()).format('ddd MMM Do, YYYY')}`},
        {field: 'title',headerName: 'Title', width: 400},
        {field: 'details', headerName: 'Content', width: 600},
        {field: 'source', headerName: 'Link', width: 200},
        {field: 'active', headerName: 'Active', width: 100},
    ]

    const showFeedback = (message, type) => {
        setMessage({
            style: type,
            content: message
        });

        setTimeout(() => {setMessage(null)}, 7000);
    }

    const editUpdate = (toggle = false) => {
        setLoading(true);

        const updateData = toggle ? {active: !currentUpdate.active} : {details: updatedDetails};

        DB.collection('updates').doc(currentUpdate.id).update(updateData)
        .then(() => {
            showFeedback('Update edited successfully', classes.success);
            setShowEditForm(false);
            setUpdatedDetails("");
            getUpdates();
        })
        .catch((error) => {
            setLoading(false);
            showFeedback(error.message, classes.error)
        })
    }

    const shareUpdate = () => {
        if (!image) {
            return showFeedback('Image required', classes.error);
        } else if (!details) {
            return showFeedback('Details required', classes.error);
        } else if (!title) {
            return showFeedback('Title required', classes.error);
        }

        setLoading(true);

        DB.collection('updates').add({
            active: true,
            image: "",
            details: details,
            source: source,
            title: title,
            postedAt: firestore.FieldValue.serverTimestamp()          
        })
        .then((updateRef) => {
            const imageRef = CDN.ref().child(`updates/images/${updateRef.id}`);

            imageRef.put(image)
            .then(() => {
                imageRef.getDownloadURL()
                .then((url) => {
                    updateRef.update({
                        image: url
                    })
                    .then(() => {
                        showFeedback('Update shared successfully!', classes.success);
                        setImage(null);
                        setTitle(null);
                        setDetails(null);
                        setSource(null);
                        setShowUpdateForm(false);
                        getUpdates();
                    })
                    .catch((error) => {
                        showFeedback(error.message, classes.error)
                        setLoading(false);
                    })
                })
                .catch((error) => {
                    showFeedback(error.message, classes.error)
                    setLoading(false);
                })
            })
            .catch((error) => {
                showFeedback(error.message, classes.error)
                setLoading(false);
            })
        })
        .catch((error) => {
            showFeedback(error.message, classes.error)
            setLoading(false);
        })
    };

    const getUpdates = () => {
        DB.collection('updates').get()
        .then((updatesSnapshot) => {
            const updatesData = [];

            updatesSnapshot.forEach((updateDoc) => {
                let update = updateDoc.data();
                update.id = updateDoc.id;

                updatesData.push(update);
            })

            setUpdates(updatesData);
            setLoading(false);
        })
    };

    useEffect(() => {
        getUpdates();
    }, []);

    return (<Grid item container xs={12}>
        {message && <SnackbarContent
            style={{zIndex: 99999999999}}
            className={message.style}
            message={message.content}
        />}
        <Grid item container xs={12} style={{minHeight: '3rem', backgroundColor: '#444', textAlign: 'center'}}>
            <Grid item xs={12} style={{position: 'relative', margin: '1rem', textAlign: 'left'}}>
                {loading ? <CircularProgress size={35} style={{color: '#f5f5f5'}}/> : <Typography variant="h6" component="h1" style={{fontWeight: '300', fontSize: '2rem', color: '#f5f5f5'}}>Updates</Typography>}
                <Typography onClick={() => setDashboardScene('index')} variant="h6" component="h1" style={{display: 'inline', fontFamily: 'helvetica', fontSize: '1rem', cursor: 'pointer', fontWeight: '200', color: '#f5f5f5'}}><BackIcon style={{verticalAlign: 'bottom'}}/> go back</Typography>
                <AddIcon onClick={() => setShowUpdateForm(true)} style={{position: 'absolute', top: '30%', right: '1rem', cursor: 'pointer', fontSize: '2.5rem', color: '#f5f5f5'}}/>
            </Grid>
        </Grid>
        <Grid item xs={12} style={{color: '#222'}}>
            <DataGrid autoHeight disableMultipleSelection onRowClick={(param) => {setCurrentUpdate(param.data); setShowEditForm(true)}} loading={loading} rows={updates} columns={columns} pageSize={25}/>

            <Dialog open={showUpdateForm} onClose={() => setShowUpdateForm(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{loading ? <CircularProgress size={25} style={{color: 'forestgreen'}}/> : 'Share an Update'}</DialogTitle>
                <DialogContentText style={{paddingLeft: '1.5rem', paddingRight: '1.5rem'}}>
                    Let donors know how things are going with an image and details about what you're up to.
                </DialogContentText>
                <DialogContent>
                    <TextField
                        disabled={loading}
                        type="file"
                        className={`${classes.input} ${classes.blockInput}`}
                        variant="outlined"
                        placeholder="image"
                        onChange={(event) => setImage(event.target.files[0])}
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline
                            }
                        }}
                    />
                    <TextField
                        fullWidth
                        disabled={loading}
                        className={`${classes.input} ${classes.blockInput}`}
                        variant="outlined"
                        placeholder="update title..."
                        value={title}
                        onChange={(event) => setTitle(event.target.value)}
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline
                            }
                        }}
                    />
                    <TextField
                        fullWidth
                        multiline
                        rows={7}
                        disabled={loading}
                        className={`${classes.input} ${classes.blockInput}`}
                        variant="outlined"
                        placeholder="update details..."
                        value={details}
                        onChange={(event) => setDetails(event.target.value)}
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline
                            }
                        }}
                    />
                    <TextField
                        disabled={loading}
                        className={`${classes.input} ${classes.blockInput}`}
                        variant="outlined"
                        placeholder="optional more info url"
                        onChange={(event) => setSource(event.target.value)}
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline
                            }
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={() => setShowUpdateForm(false)} variant="contained" style={{backgroundColor: 'transparent', color: '#222', textTransform: 'none', marginBottom: '1rem', borderRadius: '25px'}}>
                        Cancel
                    </Button>
                    <Button disabled={loading} onClick={shareUpdate} variant="contained" style={{backgroundColor: '#3f8b24', color: '#fff', textTransform: 'none', marginBottom: '1rem', borderRadius: '25px'}}>
                        Share
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog open={showEditForm} onClose={() => {setShowEditForm(false); setCurrentUpdate(null)}} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{loading ? <CircularProgress size={25} style={{color: 'forestgreen'}}/> : currentUpdate && currentUpdate.title}</DialogTitle>
                <DialogContent>
                    <p style={{marginTop: 0, marginBottom: '1rem'}}>{currentUpdate && currentUpdate.details}</p>
                    <TextField
                        fullWidth
                        multiline
                        rows={7}
                        disabled={loading}
                        className={`${classes.input} ${classes.blockInput}`}
                        variant="outlined"
                        value={updatedDetails !== "" ? updatedDetails : currentUpdate && currentUpdate.details}
                        onChange={(event) => setUpdatedDetails(event.target.value)}
                        InputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline
                            }
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={() => {setShowEditForm(false); setCurrentUpdate(null)}} variant="contained" style={{backgroundColor: 'transparent', color: '#222', textTransform: 'none', marginBottom: '1rem', borderRadius: '25px'}}>
                        Cancel
                    </Button>
                    {currentUpdate && <Button disabled={loading} onClick={() => editUpdate(true)} variant="contained" style={{backgroundColor: currentUpdate.active ? 'red' : 'blue', color: '#fff', textTransform: 'none', marginBottom: '1rem', borderRadius: '25px'}}>
                        {currentUpdate.active ? 'deactivate' : 'activate'}
                    </Button>}
                    <Button disabled={loading} onClick={editUpdate} variant="contained" style={{backgroundColor: '#3f8b24', color: '#fff', textTransform: 'none', marginBottom: '1rem', borderRadius: '25px'}}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    </Grid>)
}

export default withStyles(GlobalStyle)(Updates);