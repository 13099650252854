import React, { Fragment, useState } from 'react';

/* UI COMPONENTS */
import {
    withStyles,
    Grid,
    Typography,
    TextField,
    Button,
    CircularProgress,
    SnackbarContent,
    Paper
} from '@material-ui/core';


/* ASSETS */
import logo from '../assets/images/wordmark_dark_stacked.png';
import GlobalStyle from '../style/Global';


function LogIn({ classes, AUTH, DB, setScene }) {
    window.scrollTo(0, 0);

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const showFeedback = (message, type) => {
        setMessage({
            style: type,
            content: message
        });

        setTimeout(() => {setMessage(null)}, 3000);
    }

    const authenticateUser = () => {
        setLoading(true);
        if (!email || !password) {
            showFeedback("All fields required", classes.error);
            setLoading(false);
        } else {
            AUTH.signInWithEmailAndPassword(email, password)
            .then((currentUser) => {
                DB.collection('admins').doc(currentUser.user.uid).get()
                .then((adminDoc) => {
                    if (adminDoc.exists && adminDoc.data().active) {
                        setLoading(false);
                        setScene('dashboard')
                    } else {
                        showFeedback('Access denied', classes.error)
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    showFeedback(error.message, classes.error)
                    setLoading(false);
                })
            })
            .catch((error) => {
                showFeedback(error.message, classes.error)
                setLoading(false);
            })
        }
    };

    return(
        <div style={{backgroundColor: '#3f8b24'}}>
            {message && <SnackbarContent
                style={{zIndex: 99999999999}}
                className={message.style}
                message={message.content}
            />}
            <Grid container style={{minHeight: '100vh', paddingTop: '3rem'}} justify="center">
                <Grid item xs={10} md={4} style={{textAlign: 'center'}}>
                    <Paper elevation={3} style={{padding: '2rem', borderRadius: '25px'}}>
                    <img onClick={() => setScene('index')} src={logo} alt="logo" style={{cursor: 'pointer', width: '10rem', maxWidth: '80%'}}/>
                    <div>
                        {!loading && <Typography variant="h6" component="h1" style={{fontFamily: 'helvetica neue', fontSize: '2rem', fontWeight: '100', color: '#222', marginBottom: '1rem'}}>Admin</Typography>}
                        {loading && <CircularProgress size={20}/>}
                        <TextField
                            fullWidth
                            disabled={loading}
                            type="email"
                            className={`${classes.input} ${classes.blockInput}`}
                            variant="outlined"
                            placeholder="email..."
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                        />
                        <TextField
                            fullWidth
                            disabled={loading}
                            type="password"
                            className={`${classes.input} ${classes.blockInput}`}
                            variant="outlined"
                            placeholder="password..."
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline
                                }
                            }}
                        />
                        <Button disabled={loading} onClick={() => authenticateUser()} fullWidth variant="contained" style={{backgroundColor: '#3f8b24', color: '#fff', textTransform: 'none', marginBottom: '1rem', borderRadius: '25px'}}>log in</Button>
                    </div>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );

}

export default withStyles(GlobalStyle)(LogIn);