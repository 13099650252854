import React, { Fragment, useState } from 'react';

import LogIn from './views/LogIn';
import Dashboard from './views/Dashboard/Index';

import {
  SnackbarContent,
  withStyles
} from '@material-ui/core';

import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/firestore';
import '@firebase/storage';
import GlobalStyle from './style/Global';

const firebaseConfig = {
  apiKey: "AIzaSyB01QtU53_zuYT9PyaGOV2OJYP4OraxVfQ",
  authDomain: "champs-change-app.firebaseapp.com",
  databaseURL: "https://champs-change-app.firebaseio.com",
  projectId: "champs-change-app",
  storageBucket: "champs-change-app.appspot.com",
  messagingSenderId: "204696418799",
  appId: "1:204696418799:web:9b4df8702b83ee1cb81e1a",
  measurementId: "G-MR5FJK4K9Q"
};

firebase.initializeApp(firebaseConfig);

const AUTH = firebase.auth();
const DB = firebase.firestore();
const CDN = firebase.storage();


function App({classes}) {

  const currentUrl = new URL(window.location);
  let initialView = 'index';

  switch (currentUrl.pathname) {
    case '/':
      initialView = 'log in';
      break;
    case '/dashboard':
      initialView = 'dashboard';
      break;
    default:
      // ADD 404
      break;
  }


  const [scene, setScene] = useState(initialView);
  const [message, setMessage] = useState(null);

  const navigate = (path) => {
    switch (path) {
      case 'log in':
        window.history.replaceState(null, '', '/');
        setScene('log in');
        break;
      case 'dashboard':
        window.history.replaceState(null, '', '/dashboard');
        setScene('dashboard');
        break;
      default:
        break;
    }
  }


  return (
  
    <div className={classes.champsChange}>
      {message && <SnackbarContent
        style={{zIndex: 99999999999}}
        className={message.style}
        message={message.content}
      />}
      {scene === 'log in' && <LogIn setScene={navigate} DB={DB} AUTH={AUTH} setMessage={setMessage}/>}
      {scene === 'dashboard' && <Dashboard setScene={navigate} firestore={firebase.firestore} AUTH={AUTH} DB={DB} CDN={CDN} setMessage={setMessage}/>}
    </div>
  );
}

export default withStyles(GlobalStyle)(App);
